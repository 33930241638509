<template>
  <div
    ref="datePickerDefault"
    class="cp-input-main__wrapper cp-input-fake__wrapper cp-date-picker"
    :class="{ disabled: disabled }"
  >
    <div
      class="mr-input-default__title cd-form-input-label"
      :class="{ 'cd-form-input-label-active': visible || data.value }"
    >
      {{ data.title }}
    </div>

    <div class="cp-input-fake cp-input-radius-50" :class="data.error.class" @click="toggleVisible">
      <span v-if="data.value" class="mr-input-fake__value">{{ data.value }}</span>
      <span v-else-if="data.placeholder" class="mr-input-fake__value">{{ data.placeholder }}</span>
      <div class="icon-calendar"></div>
    </div>

    <div v-if="visible" class="mr-calendar__select-wrapper cp-input-radius-25">
      <div :key="`month_${data.date}`" class="mr-calendar__select-month cp-input-radius-25">
        <font-awesome-icon class="cp-calendar__arrow-left" size="lg" :icon="['fas', 'chevron-left']" @click="prev" />
        <span>{{ month.name }}</span>
        <font-awesome-icon class="cp-calendar__arrow-right" size="lg" :icon="['fas', 'chevron-right']" @click="next" />
      </div>

      <div class="mr-calendar__day-weeks">
        <table class="mr-calendar__days-table mr-calendar__days-table--header">
          <thead>
            <tr>
              <th v-for="day in dayOfWeeks" :key="`${data.key}_${day}`">{{ day }}</th>
            </tr>
          </thead>
        </table>

        <table class="mr-calendar__days-table cp-input-radius-25">
          <tbody>
            <tr v-for="(week, weekOfYear) in month.weeks" :key="weekOfYear">
              <td
                v-for="dayOfWeek in 7"
                :key="`${data.key}_${weekOfYear}_${dayOfWeek}`"
                class="cp-calendar__day"
                :class="{
                  active: week[dayOfWeek] && isSelectedDay({ day: week[dayOfWeek], month: month.number, year: month.year }),
                  'not-active': !week[dayOfWeek],
                  disabled: getDisabledToCurrentDate({
                    day: week[dayOfWeek],
                    month: month.number,
                    year: month.year,
                  }),
                }"
                @click="selectValue(week[dayOfWeek])"
              >
                <div class="cp-calendar__day-wrapper">
                  {{ week[dayOfWeek] }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DatePickerMixin from '@/components/form/DatePickerMixin';
import moment from 'moment';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendar, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

library.add(faCalendar, faChevronLeft, faChevronRight);

export default {
  mixins: [DatePickerMixin],
  props: {
    data: {
      type: Object,
      default() {
        return {
          key: 'date',
          type: 'date-picker',
          title: 'С даты',
          placeholder: '',
          value: '21/02/20',
          action: 'user/auth/updateLogin',
          required: true,
          error: {
            class: '',
            message: '',
          },
          validation: {
            empty: '',
            incorrect: '',
          },
        };
      },
    },
    disabledToCurrentDate: {
      type: Boolean,
      default: false,
    },
    filterKey: {
      type: String,
    },
  },
  methods: {
    getDisabledToCurrentDate(date) {
      if (this.disabledToCurrentDate) {
        const selectDate = moment(`${date.day} ${date.month} 20${date.year}`, 'DD-MM-YYYY').toDate();

        return selectDate > new Date();
      }

      return false;
    },
  },
};
</script>
