
import { Component, Watch } from '@/lib/decorator';
import { ref } from 'vue';

import TitlePage from '@/components/Title.vue';
import AppHeaderBtnAdd from '@/layouts/partials/AppHeaderBtnAdd.vue';
import ArrowReturn from '@/components/icons/ArrowReturn.vue';
import DatePicker from '@/components/form/DatePicker.vue';
import IconFont from '@/components/icons/IconFont.vue';
import FormInput from '@/components/form/Input/index.vue';
import Loading from '@/components/Loading.vue';
import FormTextarea from '@/components/form/Textarea/index.vue';
import FormSelect from '@/components/form/Select/index.vue';
import FormAutocomplete from '@/components/form/Autocomplete/index.vue';
import FormButton from '@/components/form/Button/index.vue';
import formValidate from '@/lib/formFactory/validate';
import { getCardTypes } from '@/api/cards';

import CardsDatabaseEntity from '@/store/cards/database/databaseEntity';
import Form from '@/layouts/Form';

import { getEmployeesList } from '@/api/employees';
import ResponseHandlerModule from '@/store/modules/responseHandler';

@Component({
  components: {
    TitlePage,
    AppHeaderBtnAdd,
    ArrowReturn,
    DatePicker,
    IconFont,
    FormInput,
    FormTextarea,
    FormAutocomplete,
    FormSelect,
    Loading,
    FormButton,
  },
})
export default class CardsDatabaseAdd extends Form {
  isLoading = true;

  form = ref(null);
  validate = formValidate(this.form);
  comment = this.model.comment ?? '';
  employeeName: { id: string | null; value: string } = { id: null, value: '' };
  employeesNameItems: { id: string; value: string }[] = [];
  employeesNameLoading = false;
  employeeNameStatic = '';
  employeeNameError = false;

  cardType = this.model.cardType;
  cardTypes: Record<string, { id: string; name: string }[]> = {};
  cardTypeItems = [
    {
      id: 'green',
      value: 'Зеленая',
    },
    {
      id: 'yellow',
      value: 'Желтая',
    },
    {
      id: 'red',
      value: 'Красная',
    },
  ];

  cardReason = this.model.cardReason;
  cardReasonList: { id: string; value: string; data: { id: string; name: string } }[] = [];

  cardQuantity = this.model.cardQuantity;
  cardQuantityList: { id: number; value: string }[] = [];

  enterDisabled = true;
  titlePage = '';
  isShowHelp = false;
  icon = {
    name: 'user',
    size: '19',
  };

  get model() {
    return CardsDatabaseEntity.model;
  }

  get getEditCard() {
    return CardsDatabaseEntity.editCard;
  }

  get isCardUpdated() {
    return CardsDatabaseEntity.isCardUpdated;
  }

  get modelComment() {
    return this.model.comment;
  }

  @Watch('isCardUpdated')
  onIsCardUpdated(newVal: boolean) {
    if (newVal === true) {
      CardsDatabaseEntity.updateIsCardUpdated(false);
      this.returnBefore();
    }
  }

  @Watch('modelComment')
  onChangeModelComment() {
    this.comment = this.modelComment;
  }

  prepareAutocomplete(employees: { id: string; fullName: string }[]) {
    return employees.map((employee: { id: string; fullName: string }) => {
      return {
        id: employee.id,
        value: employee.fullName,
      };
    });
  }

  async searchEmployees(value: string) {
    try {
      if (value === '') {
        return;
      }

      this.employeeNameError = false;

      this.employeesNameLoading = true;

      const nameFilter = `&filters[0][id]=fullName&filters[0][value]=${value}`;
      const result = await getEmployeesList(1, '', '', nameFilter);
      this.employeesNameItems = this.prepareAutocomplete(result.rows);

      this.employeesNameLoading = false;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
    }
  }

  setCardsTypesContent(value: string) {
    const separator = ';';

    if (!value) {
      return '';
    }

    if (!value.includes(separator)) {
      return value;
    }

    const valueToList = value
      .split(separator)
      .filter((item: string) => {
        return item;
      })
      .map((item: string) => {
        return `<li>${item}</li>`;
      })
      .join('');

    return `<ul>${valueToList}</ul>`;
  }

  handleDate(params: { day: number; month: string; year: string }) {
    CardsDatabaseEntity.setDate(params);
  }

  returnBefore() {
    this.$router.push({ name: 'cards_database' });
  }

  prepareCardReasonList(cardReasons: { id: string; name: string }[]) {
    return cardReasons.map((item: { id: string; name: string }) => {
      return {
        id: item.id,
        value: item.name,
        data: item,
      };
    });
  }

  prepareCardQuantityList(cardQuantity: { minCount: number; maxCount: number }) {
    let result: { id: number; value: string }[] = [];

    for (let i = cardQuantity.minCount; i <= cardQuantity.maxCount; i++) {
      result.push({ id: i, value: String(i) });
    }

    return result;
  }

  async updateCardType(selected: { id: string; value: string }) {
    this.cardType = selected;

    this.cardReason = { id: null, value: '' };
    this.cardReasonList = [];

    this.cardQuantity = { id: null, value: '' };
    this.cardQuantityList = [];

    this.cardReasonList = await this.prepareCardReasonList(this.cardTypes[selected.id]);
  }

  async updateCardReason(selected: { id: string; value: string; data: { minCount: number; maxCount: number } }) {
    this.cardReason = selected;

    if (selected) {
      this.cardQuantityList = this.prepareCardQuantityList(selected.data);

      const firstCardQuantity: { id: number; value: string } = this.cardQuantityList[0];

      this.cardQuantity = {
        id: firstCardQuantity.id,
        value: String(firstCardQuantity.value),
      };
    }
  }

  async save() {
    this.blockQuery(async () => {
      this.employeeNameError = false;

      const valid = this.employeeName.id;
      const validForm = await this.validate.validate();

      if (!valid) {
        this.employeeNameError = true;

        ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });
      }

      if (!validForm) {
        return false;
      }

      if (this.$route.name === 'cards_database_add') {
        await CardsDatabaseEntity.sendCreate({
          comment: this.comment,
          employee: this.employeeName.id,
          cardType: this.cardReason.id as string,
          count: this.cardQuantity.value,
        });
      }

      if (this.$route.name === 'cards_database_edit') {
        await CardsDatabaseEntity.sendEdit({
          comment: this.comment,
          employee: this.employeeName.id,
          cardType: this.cardReason.id as string,
          count: this.cardQuantity.value,
        });
      }
    });
  }

  async cancelCard() {
    await CardsDatabaseEntity.cancelCardWarning();
  }

  async deleteCard() {
    this.blockQuery(async () => {
      await CardsDatabaseEntity.deleteCardWarning();
    });
  }

  toggleHelp() {
    this.isShowHelp = !this.isShowHelp;
  }

  hideHelp() {
    this.isShowHelp = false;
  }

  initCardType() {
    this.cardType = this.cardTypeItems.find((item: { id: string; value: string }) => {
      return item.id === this.model.cardType.id;
    }) as { id: string; value: string; list?: { id: string; value: string }[] };
  }

  initCardReason() {
    this.cardReason = this.cardReasonList.find((item: { id: string; value: string }) => {
      return parseInt(item.id) === +(this.model.cardReason.id as string);
    }) as { id: string | null; value: string };
  }

  async created() {
    this.cardTypes = await getCardTypes();

    if (this.$route.name === 'cards_database_add') {
      this.titlePage = CardsDatabaseEntity.titlePage.add;
      await CardsDatabaseEntity.initForm();

      this.isLoading = false;
    }

    if (this.$route.name === 'cards_database_edit') {
      this.titlePage = CardsDatabaseEntity.titlePage.edit;
      await CardsDatabaseEntity.initEditForm();

      this.employeeName = this.model.employeeName;

      this.employeeNameStatic = this.model.employeeName.value;

      await this.initCardType();
      await this.updateCardType(this.cardType);
      await this.initCardReason();
      await this.updateCardReason(
        this.cardReason as { id: string; value: string; data: { minCount: number; maxCount: number } }
      );

      this.cardQuantity = {
        id: Math.abs(this.model.cardQuantity.id as number),
        value: String(Math.abs(+(this.model.cardQuantity.value as string))),
      };

      this.isLoading = false;
    }

    this.employeeName = this.model.employeeName;
  }

  beforeUnmount() {
    CardsDatabaseEntity.reset();
  }
}
